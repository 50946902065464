import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import client from "../contentful";
import { motion, AnimatePresence } from "framer-motion";

const HeroSlider = () => {
	const [slides, setSlides] = useState([]);
	const [currentSlide, setCurrentSlide] = useState(0);

	useEffect(() => {
		client
			.getEntries({ content_type: "hero" })
			.then((response) => {
				setSlides(response.items);
			})
			.catch(console.error);
	}, []);

	useEffect(() => {
		const interval = setInterval(() => {
			setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
		}, 5000);
		return () => clearInterval(interval);
	}, [slides]);

	if (slides.length === 0) return null;

	return (
		<section className="hero-slider">
			<AnimatePresence initial={false}>
				<motion.div
					key={currentSlide}
					className="hero-slide"
					style={{
						backgroundImage: `url(${slides[currentSlide].fields.image.fields.file.url})`,
					}}
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
					transition={{ duration: 0.5 }}
				>
					{slides[currentSlide].fields.title ||
						slides[currentSlide].fields.subtitle ||
						(slides[currentSlide].fields.buttonText && (
							<div className="hero-content">
								{slides[currentSlide].fields.title && (
									<h2>{slides[currentSlide].fields.title}</h2>
								)}
								{slides[currentSlide].fields.subtitle && (
									<p>{slides[currentSlide].fields.subtitle}</p>
								)}
								{slides[currentSlide].fields.buttonText &&
									slides[currentSlide].fields.buttonLink && (
										<Link
											to={slides[currentSlide].fields.buttonLink}
											className="btn btn-primary"
										>
											{slides[currentSlide].fields.buttonText}
										</Link>
									)}
							</div>
						))}
				</motion.div>
			</AnimatePresence>
			<div className="slider-dots">
				{slides.map((_, index) => (
					<span
						key={index}
						className={`dot ${index === currentSlide ? "active" : ""}`}
						onClick={() => setCurrentSlide(index)}
					></span>
				))}
			</div>
		</section>
	);
};

export default HeroSlider;
