import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
	FaCheckCircle,
	FaExchangeAlt,
	FaHeadset,
	FaQuoteLeft,
	FaTruck,
} from "react-icons/fa";
import client from "../contentful";
import ProductCard from "../components/ProductCard";
import HeroSlider from "../components/HeroSlider";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Home = () => {
	const [featuredProducts, setFeaturedProducts] = useState([]);
	const [categories, setCategories] = useState([]);
	const [testimonials, setTestimonials] = useState([]);

	useEffect(() => {
		// Fetch featured products
		client
			.getEntries({
				content_type: "product",
				limit: 4,
				"fields.featured": true,
			})
			.then((response) => {
				setFeaturedProducts(response.items);
			})
			.catch(console.error);

		// Fetch categories
		client
			.getEntries({
				content_type: "category",
				limit: 4,
			})
			.then((response) => {
				setCategories(response.items);
			})
			.catch(console.error);

		// Fetch testimonials
		client
			.getEntries({
				content_type: "testimonial",
				limit: 10,
			})
			.then((response) => {
				setTestimonials(response.items);
			})
			.catch(console.error);
	}, []);

	const testimonialSettings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 2000,
		arrows: false,
	};

	const getDefaultAvatar = (name) => {
		return `https://ui-avatars.com/api/?name=${encodeURIComponent(
			name
		)}&background=random&color=fff`;
	};

	return (
		<div className="home">
			<HeroSlider />

			<section className="featured-products">
				<div className="container">
					<h2 className="section-title">Seçilmiş Məhsullar</h2>
					<div className="featured-product-grid">
						{featuredProducts.map((product) => (
							<ProductCard
								key={product.sys.id}
								product={product}
								featured={true}
							/>
						))}
					</div>
					<div className="all-products-button-container">
						<Link to="/products" className="btn btn-secondary btn-lg">
							Bütün Məhsullar <span className="arrow">→</span>
						</Link>
					</div>
				</div>
			</section>

			<section className="about-preview">
				<div className="container">
					<div className="about-content">
						<div className="about-text">
							<h2 className="section-title">Haqqımızda</h2>
							<p>
								Grandclima 2003-cü ildən Azərbaycanda fəaliyyət göstərən aparıcı
								havalandırma və soyutma sistemləri təchizatçısıdır. Biz
								müştərilərimizə ən yüksək keyfiyyətli HVAC məhsulları və peşəkar
								xidmət təqdim etməyə sadiqik.
							</p>
							<ul className="about-features">
								<li>Geniş çeşidli kondisioner və havalandırma sistemləri</li>
								<li>Peşəkar quraşdırma və texniki xidmət</li>
								<li>Enerji səmərəli həllər</li>
								<li>24/7 texniki dəstək</li>
							</ul>
							<Link to="/about" className="btn btn-primary">
								Ətraflı <span className="arrow">→</span>
							</Link>
						</div>
						<div className="about-image">
							<img
								src="https://www.hvac.com/_next/image/?url=https%3A%2F%2Fd1y2bcnc5x6gss.cloudfront.net%2Fi_Stock_1309383930_fca8b7fa0c.jpg&w=3840&q=75"
								alt="Modern kondisioner sistemi"
							/>
						</div>
					</div>
				</div>
			</section>

			<section className="categories">
				<div className="container">
					<h2 className="section-title">Kateqoriyalar üzrə Alış-veriş</h2>
					<div className="category-grid">
						{categories.map((category) => (
							<Link
								key={category.sys.id}
								to={`/products?category=${category.fields.name}`}
								className="category-card"
							>
								<div className="category-content">
									<h3>{category.fields.name || "Adsız Kateqoriya"}</h3>
									<span className="category-link">Məhsulları Gör</span>
								</div>
							</Link>
						))}
					</div>
				</div>
			</section>

			<section className="why-choose-us">
				<div className="container">
					<h2 className="section-title">Niyə Bizi Seçməlisiniz?</h2>
					<div className="benefits-grid">
						<div className="benefit-card">
							<div className="benefit-icon">
								<FaTruck />
							</div>
							<h3>Pulsuz Çatdırılma</h3>
							<p>Bütün sifarişlər üçün Bakı daxilində pulsuz çatdırılma.</p>
						</div>
						<div className="benefit-card">
							<div className="benefit-icon">
								<FaCheckCircle />
							</div>
							<h3>Keyfiyyət Zəmanəti</h3>
							<p>
								Bütün məhsullarımız yüksək keyfiyyət standartlarına cavab verir.
							</p>
						</div>
						<div className="benefit-card">
							<div className="benefit-icon">
								<FaHeadset />
							</div>
							<h3>24/7 Dəstək</h3>
							<p>Həftənin 7 günü, 24 saat müştəri dəstəyi təqdim edirik.</p>
						</div>
						<div className="benefit-card">
							<div className="benefit-icon">
								<FaExchangeAlt />
							</div>
							<h3>Asan Qaytarma</h3>
							<p>14 gün ərzində problemsiz məhsul qaytarma imkanı.</p>
						</div>
					</div>
				</div>
			</section>

			<section className="testimonials">
				<div className="container">
					<h2 className="section-title">Müştəri Rəyləri</h2>
					<Slider {...testimonialSettings}>
						{testimonials.map((testimonial) => (
							<div key={testimonial.sys.id} className="testimonial-slide">
								<div className="testimonial-card">
									<FaQuoteLeft className="quote-icon" />
									<div className="testimonial-content">
										{testimonial.fields.content}
									</div>
									<div className="testimonial-author">
										<img
											src={
												testimonial.fields.authorImage?.fields.file.url ||
												getDefaultAvatar(testimonial.fields.authorName)
											}
											alt={testimonial.fields.authorName}
											className="author-image"
										/>
										<div className="author-info">
											<h4>{testimonial.fields.authorName}</h4>
											<p>{testimonial.fields.authorTitle}</p>
										</div>
									</div>
								</div>
							</div>
						))}
					</Slider>
				</div>
			</section>
		</div>
	);
};

export default Home;
