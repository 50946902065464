import React, { useState, useEffect } from "react";
import client from "../contentful";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";

const Contact = () => {
	const [contactContent, setContactContent] = useState(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		client
			.getEntries({
				content_type: "about",
			})
			.then((response) => {
				if (response.items.length > 0 && response.items[0].fields.contact) {
					setContactContent(response.items[0].fields.contact);
				} else {
					console.error("No 'about' entries found or no contact field");
				}
				setLoading(false);
			})
			.catch((error) => {
				console.error("Error fetching contact content:", error);
				setLoading(false);
			});
	}, []);

	const options = {
		renderNode: {
			[BLOCKS.PARAGRAPH]: (node, children) => (
				<p className="contact-paragraph">{children}</p>
			),
			[BLOCKS.HEADING_1]: (node, children) => (
				<h1 className="contact-h1">{children}</h1>
			),
			[BLOCKS.HEADING_2]: (node, children) => (
				<h2 className="contact-h2">{children}</h2>
			),
			[BLOCKS.HEADING_3]: (node, children) => (
				<h3 className="contact-h3">{children}</h3>
			),
			[BLOCKS.UL_LIST]: (node, children) => (
				<ul className="contact-list">{children}</ul>
			),
			[BLOCKS.OL_LIST]: (node, children) => (
				<ol className="contact-list">{children}</ol>
			),
			[BLOCKS.LIST_ITEM]: (node, children) => (
				<li className="contact-list-item">{children}</li>
			),
			[INLINES.HYPERLINK]: (node, children) => (
				<a
					href={node.data.uri}
					className="contact-link"
					target="_blank"
					rel="noopener noreferrer"
				>
					{children}
				</a>
			),
			[BLOCKS.TABLE]: (node, children) => (
				<table className="contact-table">{children}</table>
			),
			[BLOCKS.TABLE_ROW]: (node, children) => <tr>{children}</tr>,
			[BLOCKS.TABLE_CELL]: (node, children) => (
				<td className="contact-table-cell">{children}</td>
			),
			[BLOCKS.TABLE_HEADER_CELL]: (node, children) => (
				<th className="contact-table-header-cell">{children}</th>
			),
		},
	};

	if (loading) return <div className="loading">Yüklənir...</div>;
	if (!contactContent) return <div className="error">Məlumat tapılmadı.</div>;

	return (
		<div className="contact-page">
			<div className="container">
				<h1 className="section-title">Əlaqə</h1>
				<div className="contact-content">
					<div className="contact-info">
						{documentToReactComponents(contactContent, options)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Contact;
