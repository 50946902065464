import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import client from "../contentful";
import { FaTruck, FaUndo } from "react-icons/fa";
import { motion } from "framer-motion";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";

const Product = () => {
	const [product, setProduct] = useState(null);
	const [loading, setLoading] = useState(true);
	const [mainImage, setMainImage] = useState(null);
	const { id } = useParams();

	useEffect(() => {
		client
			.getEntry(id)
			.then((response) => {
				setProduct(response.fields);
				setMainImage(response.fields.image[0].fields.file.url);
				setLoading(false);
			})
			.catch((error) => {
				console.error("Error fetching product:", error);
				setLoading(false);
			});
	}, [id]);

	if (loading) return <div className="loading">Yüklənir...</div>;
	if (!product) return <div className="error">Məhsul tapılmadı.</div>;

	const handleImageClick = (imageUrl) => {
		setMainImage(imageUrl);
	};

	const options = {
		renderNode: {
			[BLOCKS.PARAGRAPH]: (node, children) => (
				<p className="description-paragraph">{children}</p>
			),
			[BLOCKS.HEADING_1]: (node, children) => (
				<h1 className="description-h1">{children}</h1>
			),
			[BLOCKS.HEADING_2]: (node, children) => (
				<h2 className="description-h2">{children}</h2>
			),
			[BLOCKS.HEADING_3]: (node, children) => (
				<h3 className="description-h3">{children}</h3>
			),
			[BLOCKS.UL_LIST]: (node, children) => (
				<ul className="description-list">{children}</ul>
			),
			[BLOCKS.OL_LIST]: (node, children) => (
				<ol className="description-list">{children}</ol>
			),
			[BLOCKS.LIST_ITEM]: (node, children) => (
				<li className="description-list-item">{children}</li>
			),
			[INLINES.HYPERLINK]: (node, children) => (
				<a href={node.data.uri} className="description-link">
					{children}
				</a>
			),
			[BLOCKS.TABLE]: (node, children) => (
				<table className="description-table">{children}</table>
			),
			[BLOCKS.TABLE_ROW]: (node, children) => <tr>{children}</tr>,
			[BLOCKS.TABLE_CELL]: (node, children) => <td>{children}</td>,
			[BLOCKS.TABLE_HEADER_CELL]: (node, children) => <th>{children}</th>,
		},
	};

	return (
		<div className="product-page">
			<div className="container">
				<motion.div
					className="product-details"
					initial={{ opacity: 0, y: 20 }}
					animate={{ opacity: 1, y: 0 }}
					transition={{ duration: 0.5 }}
				>
					<div className="product-main-info">
						<div className="product-images">
							<div className="main-image">
								<img src={mainImage} alt={product.title} />
							</div>
							<div className="thumbnail-images">
								{product.image.map((img, index) => (
									<img
										key={index}
										src={img.fields.file.url}
										alt={`${product.title} - ${index + 1}`}
										onClick={() => handleImageClick(img.fields.file.url)}
										className={
											mainImage === img.fields.file.url ? "active" : ""
										}
									/>
								))}
							</div>
						</div>
						<div className="product-info">
							<h1 className="product-title">{product.title}</h1>
							<div className="product-meta">
								{product.category && (
									<span className="product-category">
										Kateqoriya: {product.category.fields.name}
									</span>
								)}
								{/* <div className="product-rating">
									{[...Array(5)].map((_, index) => (
										<FaStar
											key={index}
											color={index < product.rating ? "#ffc107" : "#e4e5e9"}
										/>
									))}
									<span>({product.reviewCount} rəy)</span>
								</div> */}
							</div>
							<p className="product-price">{product.price} AZN</p>
							<div className="product-actions">
								{/* <button className="btn btn-secondary">İndi al</button> */}
							</div>
							<div className="product-info-footer">
								<div className="info-item">
									<FaTruck />
									<span>Pulsuz çatdırılma</span>
								</div>
								<div className="info-item">
									<FaUndo />
									<span>30 gün ərzində qaytarma</span>
								</div>
							</div>
						</div>
					</div>
					{product.description && (
						<motion.div
							className="product-features"
							initial={{ opacity: 0, y: 20 }}
							animate={{ opacity: 1, y: 0 }}
							transition={{ duration: 0.5, delay: 0.2 }}
						>
							<h2>Məhsul xüsusiyyətləri</h2>
							<div className="product-description">
								{documentToReactComponents(product.description, options)}
							</div>
						</motion.div>
					)}
				</motion.div>
			</div>
		</div>
	);
};

export default Product;
