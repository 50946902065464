import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaStar } from "react-icons/fa";

const ProductCard = ({ product, featured }) => {
	// Declare the hover state at the top level
	const [isHovered, setIsHovered] = useState(false);

	// Early return if product is not available
	if (!product) {
		return null;
	}

	const imageUrl =
		product.fields.image?.[0]?.fields?.file?.url ||
		"https://via.placeholder.com/300x300?text=No+Image";
	const secondImageUrl = product.fields.image?.[1]?.fields?.file?.url || null; // Set to null if the second image does not exist

	const productId = product.sys?.id || "unknown";
	const title = product.fields?.title || "Untitled Product";
	const price = product.fields?.price || "N/A";
	const discountPercentage = product.fields?.discountPercentage || 0;

	const discountedPrice =
		discountPercentage > 0
			? (price * (1 - discountPercentage / 100)).toFixed(2)
			: null;

	return (
		<Link
			to={`/product/${productId}`}
			className={`product-card ${featured ? "featured" : ""}`}
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
		>
			<div className="product-image">
				<img
					src={isHovered && secondImageUrl ? secondImageUrl : imageUrl}
					alt={title}
				/>
				{discountPercentage > 0 && (
					<span className="discount-badge">-{discountPercentage}%</span>
				)}
				{featured && (
					<span className="featured-badge">
						<FaStar /> Seçilmiş
					</span>
				)}
			</div>
			<div className="product-card-content">
				<h3>{title}</h3>
				<div className="price-container">
					{discountedPrice ? (
						<>
							<p className="product-price discounted">{discountedPrice} AZN</p>
							<p className="original-price">{price} AZN</p>
						</>
					) : (
						<p className="product-price">{price} AZN</p>
					)}
				</div>
			</div>
		</Link>
	);
};

export default ProductCard;
