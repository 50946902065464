import React, { useState, useEffect, useCallback, useRef } from "react";
import { useLocation } from "react-router-dom";
import client from "../contentful";
import ProductCard from "../components/ProductCard";
import { FaSearch, FaFilter } from "react-icons/fa";
import { motion, AnimatePresence } from "framer-motion";
import Pagination from "../components/Pagination";

const Products = () => {
	// State Variables
	const [products, setProducts] = useState([]);
	const [categories, setCategories] = useState([]);
	const [selectedCategory, setSelectedCategory] = useState("");
	const [selectedSubCategory, setSelectedSubCategory] = useState("");
	const [searchTerm, setSearchTerm] = useState("");
	const [sortOption, setSortOption] = useState("name");
	const [priceRange, setPriceRange] = useState([0, 100000]);
	const [showFilters, setShowFilters] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const location = useLocation();
	const [currentPage, setCurrentPage] = useState(1);
	const productsPerPage = 12;
	const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
	const searchDebounceTimer = useRef(null);
	const [expandedCategories, setExpandedCategories] = useState({});

	// Fetch Products
	const fetchProducts = useCallback(() => {
		setIsLoading(true);
		client
			.getEntries({
				content_type: "product",
				...(debouncedSearchTerm && {
					"fields.title[match]": debouncedSearchTerm,
				}),
				...(selectedCategory && {
					"fields.category.sys.contentType.sys.id": "category",
					"fields.category.fields.name":
						selectedSubCategory || selectedCategory,
				}),
				"fields.price[gte]": priceRange[0],
				"fields.price[lte]": priceRange[1],
				order:
					sortOption === "name"
						? "fields.title"
						: sortOption === "price-low"
						? "fields.price"
						: "-fields.price",
			})
			.then((response) => {
				setProducts(response.items);
				setIsLoading(false);
			})
			.catch((error) => {
				console.error(error);
				setIsLoading(false);
			});
	}, [
		debouncedSearchTerm,
		selectedCategory,
		selectedSubCategory,
		priceRange,
		sortOption,
	]);

	// Fetch Categories
	useEffect(() => {
		client
			.getEntries({ content_type: "category" })
			.then((response) => {
				const categoriesWithSubCategories = response.items.map((category) => ({
					...category,
					subCategories: category.fields.subCategory || [],
				}));
				setCategories(categoriesWithSubCategories);
			})
			.catch((error) => {
				console.error(error);
			});
	}, []);

	// Fetch Products When Dependencies Change
	useEffect(() => {
		fetchProducts();
	}, [
		fetchProducts,
		selectedCategory,
		selectedSubCategory,
		debouncedSearchTerm,
		priceRange,
		sortOption,
	]);

	// Handle Search Input with Debounce
	const handleSearch = (e) => {
		const term = e.target.value;
		setSearchTerm(term);

		if (searchDebounceTimer.current) {
			clearTimeout(searchDebounceTimer.current);
		}

		searchDebounceTimer.current = setTimeout(() => {
			setDebouncedSearchTerm(term);
		}, 300);
	};

	// Handle Sorting Option Change
	const handleSort = (e) => {
		setSortOption(e.target.value);
	};

	// Handle Category Selection
	const handleCategoryFilter = (categoryName, subCategoryName = "") => {
		setSelectedCategory(categoryName);
		setSelectedSubCategory(subCategoryName);
		setCurrentPage(1); // Reset to first page on filter change
		fetchProducts(); // Call API to fetch related products
	};

	// Handle Price Range Change
	const handlePriceRange = (e, index) => {
		const newRange = [...priceRange];
		newRange[index] = parseInt(e.target.value, 10) || 0;
		setPriceRange(newRange);
	};

	// Toggle Category Expansion in Sidebar
	const toggleCategory = (categoryId) => {
		setExpandedCategories((prev) => ({
			...prev,
			[categoryId]: !prev[categoryId],
		}));
	};

	// Recursive Function to Render Category Tree
	const renderCategoryTree = (category) => (
		<li key={category.sys.id}>
			<div
				className="category-item"
				onClick={() => toggleCategory(category.sys.id)}
			>
				<span className="toggle-icon">
					{expandedCategories[category.sys.id] ? "▼" : "▶"}
				</span>
				<span
					className={selectedCategory === category.fields.name ? "active" : ""}
					onClick={() => handleCategoryFilter(category.fields.name)}
				>
					{category.fields.name}
				</span>
			</div>
			{expandedCategories[category.sys.id] &&
				category.subCategories.length > 0 && (
					<ul className="subcategory-list">
						{category.subCategories.map((subCategoryId) => {
							const subCategory = categories.find(
								(cat) => cat.sys.id === subCategoryId.sys.id
							);
							return subCategory ? renderCategoryTree(subCategory) : null;
						})}
					</ul>
				)}
		</li>
	);

	return (
		<div className="products-page">
			{/* Hero Section */}
			<div className="products-hero">
				<div className="container">
					<motion.h1
						className="hero-title"
						initial={{ opacity: 0, y: -20 }}
						animate={{ opacity: 1, y: 0 }}
						transition={{ duration: 0.5 }}
					>
						Məhsullarımız
					</motion.h1>
					<motion.p
						className="hero-subtitle"
						initial={{ opacity: 0, y: 20 }}
						animate={{ opacity: 1, y: 0 }}
						transition={{ duration: 0.5, delay: 0.2 }}
					>
						Yüksək keyfiyyətli məhsullar, sərfəli qiymətlər
					</motion.p>
				</div>
			</div>

			{/* Main Container */}
			<div className="container">
				<div className="products-layout">
					{/* Sidebar */}
					<aside className={`products-sidebar ${showFilters ? "show" : ""}`}>
						<div className="sidebar-widget">
							<h3>Kateqoriyalar</h3>
							<ul className="category-tree">
								{categories
									.filter(
										(category) =>
											!categories.some((cat) =>
												cat.subCategories.some(
													(subCat) => subCat.sys.id === category.sys.id
												)
											)
									)
									.map((category) => renderCategoryTree(category))}
							</ul>
						</div>

						{/* Price Range Widget */}
						<div className="sidebar-widget">
							<h3>Qiymət aralığı</h3>
							<div className="price-range-inputs">
								<div>
									<label htmlFor="min-price" className="price-range-label">
										Min:
									</label>
									<input
										id="min-price"
										type="number"
										value={priceRange[0]}
										onChange={(e) => handlePriceRange(e, 0)}
										min="0"
										max={priceRange[1]}
									/>
								</div>
								<span>-</span>
								<div>
									<label htmlFor="max-price" className="price-range-label">
										Max:
									</label>
									<input
										id="max-price"
										type="number"
										value={priceRange[1]}
										onChange={(e) => handlePriceRange(e, 1)}
										min={priceRange[0]}
										max="100000"
									/>
								</div>
							</div>
						</div>
					</aside>

					{/* Main Content */}
					<main className="products-main">
						{/* Products Header */}
						<div className="products-header">
							<div className="products-found">
								{products.length} məhsul tapıldı
							</div>
							<div className="products-actions">
								<div className="search-wrapper">
									<FaSearch className="search-icon" />
									<input
										type="text"
										placeholder="Məhsul axtar..."
										value={searchTerm}
										onChange={handleSearch}
										className="search-input"
									/>
								</div>
								<select
									value={sortOption}
									onChange={handleSort}
									className="sort-select"
								>
									<option value="name">Ada görə</option>
									<option value="price-low">Qiymət: Aşağıdan yuxarıya</option>
									<option value="price-high">Qiymət: Yuxarıdan aşağıya</option>
								</select>
								<button
									className="filter-toggle"
									onClick={() => setShowFilters(!showFilters)}
								>
									<FaFilter /> Filterlər
								</button>
							</div>
						</div>

						{/* Loading Indicator */}
						{isLoading ? (
							<div className="loading-spinner">Yüklənir...</div>
						) : (
							<motion.div
								className="product-grid"
								initial={{ opacity: 0 }}
								animate={{ opacity: 1 }}
								transition={{ duration: 0.5 }}
							>
								<AnimatePresence>
									{products
										.slice(
											(currentPage - 1) * productsPerPage,
											currentPage * productsPerPage
										)
										.map((product) => (
											<motion.div
												key={product.sys.id}
												layout
												initial={{ opacity: 0 }}
												animate={{ opacity: 1 }}
												exit={{ opacity: 0 }}
												transition={{ duration: 0.3 }}
											>
												<ProductCard product={product} />
											</motion.div>
										))}
								</AnimatePresence>
							</motion.div>
						)}

						{/* Pagination */}
						<Pagination
							currentPage={currentPage}
							totalPages={Math.ceil(products.length / productsPerPage)}
							onPageChange={(pageNumber) => setCurrentPage(pageNumber)}
						/>
					</main>
				</div>
			</div>
		</div>
	);
};

export default Products;
