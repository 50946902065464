import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
	return (
		<div className="not-found">
			<div className="container">
				<h1>404</h1>
				<h2>Səhifə Tapılmadı</h2>
				<p>Üzr istəyirik, axtardığınız səhifə tapılmadı.</p>
				<Link to="/" className="btn btn-primary">
					Ana Səhifəyə Qayıt
				</Link>
			</div>
		</div>
	);
};

export default NotFound;
