import React, { useState, useEffect } from "react";
import client from "../contentful";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

const About = () => {
	const [aboutContent, setAboutContent] = useState(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		client
			.getEntries({
				content_type: "about",
			})
			.then((response) => {
				if (response.items.length > 0) {
					setAboutContent(response.items[0].fields);
				} else {
					console.error("No 'about' entries found");
				}
				setLoading(false);
			})
			.catch((error) => {
				console.error("Error fetching about content:", error);
				setLoading(false);
			});
	}, []);

	if (loading) return <div className="loading">Yüklənir...</div>;
	if (!aboutContent) return <div className="error">Məlumat tapılmadı.</div>;

	return (
		<div className="about-page">
			<div className="container">
				<h1 className="section-title">Haqqımızda</h1>
				<div className="about-content">
					<div className="about-text">
						{documentToReactComponents(aboutContent.content)}
					</div>
					{aboutContent.image && (
						<div className="about-image">
							<img
								src={aboutContent.image.fields.file.url}
								alt="Grandclima HVAC sistemləri"
							/>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default About;
