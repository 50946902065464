import React, { useState, useEffect } from "react";
import client from "../contentful";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { motion } from "framer-motion";

const defaultImages = [];

const Services = () => {
	const [services, setServices] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		client
			.getEntries({
				content_type: "service",
			})
			.then((response) => {
				setServices(response.items);
				setLoading(false);
			})
			.catch((error) => {
				console.error("Error fetching services:", error);
				setLoading(false);
			});
	}, []);

	if (loading) return <div className="loading">Yüklənir...</div>;

	return (
		<div className="services-page">
			<div className="container">
				<motion.h1
					className="section-title"
					initial={{ opacity: 0, y: -20 }}
					animate={{ opacity: 1, y: 0 }}
					transition={{ duration: 0.5 }}
				>
					Xidmətlərimiz
				</motion.h1>
				<div className="services-grid">
					{services.map((service, index) => (
						<motion.div
							key={service.sys.id}
							className="service-card"
							initial={{ opacity: 0, y: 20 }}
							animate={{ opacity: 1, y: 0 }}
							transition={{ duration: 0.5, delay: index * 0.1 }}
						>
							<div className="service-image">
								<img
									src={
										service.fields.image?.fields.file.url ||
										defaultImages[index % defaultImages.length]
									}
									alt={service.fields.name}
								/>
							</div>
							<div className="service-content">
								<h2>{service.fields.name}</h2>
								<div className="service-description">
									{documentToReactComponents(service.fields.content)}
								</div>
							</div>
						</motion.div>
					))}
				</div>
			</div>
		</div>
	);
};

export default Services;
