import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";

const Header = () => {
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	const toggleMenu = () => {
		setIsMenuOpen(!isMenuOpen);
	};

	return (
		<header className="main-header">
			<div className="container">
				<div className="header-content">
					<Link to="/" className="logo">
						<img src="/logo.svg" alt="Grandclima Logo" className="logo-image" />
						<span className="logo-text">Grandclima</span>
					</Link>
					<button className="mobile-menu-toggle" onClick={toggleMenu}>
						{isMenuOpen ? <FaTimes /> : <FaBars />}
					</button>
					<nav className={`main-nav ${isMenuOpen ? "open" : ""}`}>
						<ul>
							<li>
								<Link to="/" onClick={toggleMenu}>
									Ana Səhifə
								</Link>
							</li>
							<li>
								<Link to="/products" onClick={toggleMenu}>
									Məhsullar
								</Link>
							</li>
							<li>
								<Link to="/about" onClick={toggleMenu}>
									Haqqımızda
								</Link>
							</li>
							<li>
								<Link to="/services" onClick={toggleMenu}>
									Xidmətlərimiz
								</Link>
							</li>
							<li>
								<Link to="/contact" onClick={toggleMenu}>
									Əlaqə
								</Link>
							</li>
						</ul>
					</nav>
				</div>
			</div>
		</header>
	);
};

export default Header;
